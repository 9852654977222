export const maskPhone = (value :string) => {
    const numericValue = value.replace(/\D/g, '');

    let maskedValue = '';
    if (numericValue.length <= 2) {
      maskedValue = numericValue;
    } else if (numericValue.length <= 7) {
      maskedValue = `(${numericValue.slice(0, 2)}) ${numericValue.slice(2)}`;
    } else if (numericValue.length <= 11) {
      maskedValue = `(${numericValue.slice(0, 2)}) ${numericValue.slice(
        2,
        7,
      )}-${numericValue.slice(7)}`;
    } else {
      maskedValue = `(${numericValue.slice(0, 2)}) ${numericValue.slice(
        2,
        7,
      )}-${numericValue.slice(7, 11)}`;
    }

    return maskedValue;
}