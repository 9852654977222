'use client';

import React, { FC } from 'react';
import { Button } from '@/components/Button';
import { motion } from 'framer-motion';

interface ModalProps {
  isOpen: boolean;
  haveSvg: boolean;
  onClose: () => void;
  children: React.ReactNode;
  className?: string;
}

const icon = {
  hidden: {
    pathLength: 0,
  },
  visible: {
    pathLength: 1,
    strokeWidth: '2.30894',
    transition: {
      default: { duration: 1, ease: 'easeInOut' },
      duration: 1,
    },
  },
};

export const Modal: FC<ModalProps> = ({ className, isOpen, haveSvg, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className='fixed top-0 left-0 w-full h-full flex items-center justify-center pt-0 md:pt-20' onClick={onClose}>
      <motion.div
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        transition={{
          type: 'spring',
          stiffness: 260,
          damping: 20,
        }}
        className={className}
        onClick={(e) => e.stopPropagation()} // Evita que o clique dentro do modal seja propagado para o pai
      >
        {haveSvg ? (
          <div className='flex justify-center mt-5 mb-3'>
            <motion.svg width='96' height='99' viewBox='0 0 96 99' fill='none'
                        xmlns='http://www.w3.org/2000/svg'>
              <motion.path
                variants={icon}
                initial='hidden'
                animate='visible'
                d='M94.5 49.3536C94.5 75.822 73.6422 97.2072 48 97.2072C22.3578 97.2072 1.5 75.822 1.5 49.3536C1.5 22.8852 22.3578 1.5 48 1.5C73.6422 1.5 94.5 22.8852 94.5 49.3536Z'
                stroke='white' stroke-width='3' />
              <motion.path
                d='M40.1269 59.4017C39.2594 60.2094 37.9151 60.209 37.0479 59.401L27.002 50.0401C26.1368 49.2338 24.7961 49.2315 23.9281 50.0347L22.7856 51.0917C21.8216 51.9837 21.8189 53.5069 22.7798 54.4023L37.0472 67.6969C37.9147 68.5052 39.2595 68.5052 40.127 67.6969L74.5677 35.6046C75.5262 34.7113 75.5262 33.1926 74.5677 32.2994L73.4442 31.2526C72.5771 30.4445 71.2328 30.4442 70.3652 31.2519L40.1269 59.4017Z'
                fill='white' />
            </motion.svg>
          </div>
        ) : null}

        {children}

        <div className='flex justify-center mt-5'>
          <Button className='transition modal-close-button cursor-pointer' onClick={onClose}>
            <span>Fechar</span>
          </Button>
        </div>
      </motion.div>
    </div>
  );
};